import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const CatalogSingle = ({ data }) => {
  return  <Layout>
  <article className="sheet">
    <HelmetDatoCms seo={data.datoCmsCatalog.seoMetaTags} />
    <div className="sheet__inner">
      <div className="sheet__gallery">
        <Img fluid={data.datoCmsCatalog.coverImage.fluid} />
      </div>
    </div>
  </article>
</Layout>;
};

export default CatalogSingle;


export const query = graphql`
  query CatalogQuery($slug: String!) {
    datoCmsCatalog(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      excerpt
      gallery {
        fluid(maxWidth: 200, imgixParams: { fm: "jpg", auto: "compress" }) {
          src
        }
      }
      coverImage {
        url
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`